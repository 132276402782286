<template>
  <div class="w-full">
    <h2 class="font-bold mt-8">
      Verifica il numero di cellulare
    </h2>
    <p>
      Inserisci il codice che ti abbiamo inviato tramite SMS
    </p>
    <div class="flex my-16 text-xl">
      <div class="pr-3 text-gray-600">+39</div>
      <div class=" font-bold">{{ phonenumber }}</div>
    </div>
    <div class="flex my-8 shadow-sm items-baseline">
      <input
        type="tel"
        class="w-full border py-4 px-4 border-gray-300 rounded-md"
        v-model="s_otp"
      />
    </div>
    <div class="grid grid-cols-2 gap-4 place-items-end">
      <div class="w-full text-right">
        <p>Non hai ricevuto l'sms?</p>
        <button
          class="border border-secondary-500 text-secondary-500 w-full h-12 btn"
        >
          Invia di nuovo
        </button>
      </div>
      <div class="w-full text-right">
        <q-button
          class="w-full h-12"
          :cta="true"
          :loading="loading"
          @click.once="otpConfirm"
        >
          Conferma
        </q-button>
      </div>
      <div class="mt-12">
        <button
          @click="$emit('prevstep')"
          class="text-secondary-500 w-full text-center"
        >
          <i class="mdi mdi-arrow-left"></i>
          Torna indietro
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState("createstore", ["phonenumber", "otp"]),
    s_otp: {
      get() {
        return this.otp;
      },
      set(v) {
        this.SET_OTP(v);
      }
    }
  },
  methods: {
    ...mapMutations("createstore", ["SET_OTP"]),
    ...mapActions("createstore", ["confirmOtp"]),
    async otpConfirm() {
      try {
        this.loading = true;
        await this.confirmOtp();
        this.$emit("nexstep");
      } catch (error) {
        console.log("errore OTP");
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style>
.shape {
  width: 500px;
  height: 400px;

  animation-name: appear;
  animation-duration: 10s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.shape .fil0 {
  fill: url(#header-shape-gradient) #fff;
}

#header-shape-gradient {
  --color-stop: theme("colors.secondary.300"); /*#f12c06;*/
  --color-bot: theme("colors.secondary.500"); /*#faed34;*/
}

@keyframes appear {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  /* 50% {
    
    opacity: 0.75;
  } */
  10% {
    transform: translateX(0%);
    opacity: 1;
  }
  95% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(50%);
    opacity: 0;
  }
}
</style>
